:root {
  --primary-blue-color: #00008B;
  --secondary-gray-color: #4a5568;
  --scroller-color: #888;
}

/* Existing styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}



/* Custom scrollbar styles */
.scrollable-content {
  scrollbar-width: thin;
  scrollbar-color: var(--scroller-color) #f1f1f1;
}

.scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: var(--scroller-color);
  border-radius: 4px;
  border: 2px solid #f1f1f1;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .App-header > div > div {
    flex-direction: column;
    align-items: flex-start;
  }  
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  z-index: 1000;
  border-bottom: 2px solid var(--primary-blue-color);
}

@media (min-width: 768px) {
  .header-container {
    flex-direction: row;
  }
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}

.video-item {
  width: calc(40% - 10px);
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .video-item {
    width: 80%;
    margin: 0 auto 20px;
  }
}

.watch-demo-button {
  margin-top: 30px;
  width: 250px;
  height: 50px;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  background-color: rgb(0, 0, 139);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.watch-demo-button:hover {
  background-color: #2d3748;
}

.footer-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-content p {
  margin: 0;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: left;
  }
}


.header-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 7px 0;
  flex: 1 1 80%;
}

@media (min-width: 768px) {
  .header-nav {
    justify-content: flex-end;
  }
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-field label {
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
  color: #000000;
  font-size: 20px;
  margin-right: 10px;
  text-align: left;
}

.form-field input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-field select {
  
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (min-width: 800px) {
  .form-row {
    flex-direction: row;
  }

  .form-field {    
    flex-direction: row;
  }
  
  .form-field label {
    width: 30%;        
  }
  
}


.responsive-form {
  width: 100%;
  border: none;
}

@media (min-width: 768px) {
  .responsive-form {
    width: 60%;
    border: 2px solid var(--secondary-gray-color);
    border-radius: 5px;    
  }
}

.form-field label.state-label {
  text-align: left;
  font-weight: bold;  
}


@media (min-width: 768px) {
.form-field label.state-label {
    margin-right: 10px;
    width: 20%;
    padding-left: 10px;
  }

  
}

.form-field select.left-select-box {
  width: 50%   ; 
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (min-width: 768px) {
  .form-field select.left-select-box{      
      width: 25%;      
    }
  }          


  .form-field select.right-select-box {
    width: 50%   ; 
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  @media (min-width: 768px) {
    .form-field select.right-select-box{      
        width: 15%;      
      }
    }            